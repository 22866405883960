declare module "vue-router" {
  interface RouteMeta {
    allowedRoles?: Set<role> | role[];
  }
}

declare module "nuxt/schema" {
  interface PageMeta {
    allowedRoles?: Set<role> | role[];
  }
}

/**
 * Named route middleware to check if a user is authorised.
 */
export default defineNuxtRouteMiddleware(async (to, from) => {
  if (import.meta.server) return;

  const user = await getCurrentUser();
  if (!user)
    return navigateTo({
      name: "sign-in",
      query: { redirect: to.fullPath },
    });

  let allowedRoles = to.meta.allowedRoles;
  if (Array.isArray(allowedRoles)) allowedRoles = new Set(allowedRoles);
  if (allowedRoles === undefined) return;

  const userStore = useUser();
  await userStore.ready;

  const { isAdmin, role } = userStore;
  if (isAdmin || (role && allowedRoles.has(role))) return;
  return abortNavigation(
    createError({
      statusCode: 401,
      statusMessage: "You are not authorized to view this page.",
    })
  );
});
